(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/query-draw-data-async.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/query-draw-data-async.js');
"use strict";



const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const {
  getProductBrand
} = svs.isServer ? require('../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6') : svs.components.lbUtils.brandMapping.fn;
const getProductClassName = svs.isServer ? trinidad.components.require('utils', 'products').getProductClassName : svs.utils.products.getProductClassName;
const mockableImports = svs.isServer ? {
  getJackpots: trinidad.components.require('content', 'shared').jackpots.getJackpots,
  ttlCache: new trinidad.ttlcache()
} : {
  getJackpots: () => null,
  ttlCache: {
    get: () => null,
    set: () => null
  }
};
const ONE_MINUTE = 60 * 1000;
const FRIDAY = 5;
const SATURDAY = 6;
const USUAL_CACHE_TIME = 5 * ONE_MINUTE;
const SHORT_CACHE_TIME = ONE_MINUTE;
const NUMBER_OF_POOL_DRAWS_TO_CERTAINLY_QUERY_ANYTHING_FROM_TODAY_AND_TOMORROW = 200;

async function queryDrawDataAsync(_ref) {
  let {
    itemConfig,
    cacheKey,
    req
  } = _ref;
  const allPoolBrandingsByPriority = itemConfig.productsByPriority.map(item => item.branding);
  const preselection = _getPreselectionIfApplicable({
    itemConfig,
    allPoolBrandingsByPriority
  });

  const cachedResult = mockableImports.ttlCache.get(cacheKey);
  const hasCachedResultWhichMatchesPreselection = cachedResult && cachedResult.preselection && cachedResult.preselection.branding === preselection.branding && cachedResult.preselection.brandingIfDrawDataExists === preselection.brandingIfDrawDataExists;
  if (hasCachedResultWhichMatchesPreselection) {
    return cachedResult.drawData || null;
  }
  const sortedDrawResponse = await _querySortedDraws({
    req,
    productName: preselection.branding
  });
  const sortedDrawsWithJackpot = await _extractDrawsFromResponseAndIncludeJackpots({
    req,
    sortedDrawResponse,
    allPoolBrandingsByPriority
  });
  const drawData = _getBestMatchingDrawItem({
    sortedDrawsWithJackpot,
    preselection,
    allPoolBrandingsByPriority
  });

  const newCachedResult = {
    drawData: null,
    preselection
  };
  let cacheTime = SHORT_CACHE_TIME;
  if (drawData !== null && drawData !== void 0 && drawData.closeTime) {
    newCachedResult.drawData = drawData;
    const millisecondsToCloseTime = new Date(drawData.closeTime).getTime() - Date.now();
    cacheTime = millisecondsToCloseTime < USUAL_CACHE_TIME ? SHORT_CACHE_TIME : USUAL_CACHE_TIME;
  } else if (preselection.branding) {
    newCachedResult.drawData = {
      branding: preselection.branding
    };
    cacheTime = USUAL_CACHE_TIME;
  }
  mockableImports.ttlCache.set(cacheKey, newCachedResult, cacheTime);
  return newCachedResult.drawData;
}

function _getPreselectionIfApplicable(_ref2) {
  let {
    itemConfig,
    allPoolBrandingsByPriority
  } = _ref2;
  const {
    selectedProduct,
    isOverriddenByStryktipset
  } = itemConfig;
  if (allPoolBrandingsByPriority.includes(selectedProduct)) {
    return {
      branding: selectedProduct,
      brandingIfDrawDataExists: null
    };
  }
  if (isOverriddenByStryktipset === 'friday-saturday') {
    const currentWeekday = new Date().getDay();
    if (currentWeekday === FRIDAY || currentWeekday === SATURDAY) {
      return {
        branding: null,
        brandingIfDrawDataExists: 'stryktipset'
      };
    }
  }
  return {
    branding: null,
    brandingIfDrawDataExists: null
  };
}

async function _querySortedDraws(_ref3) {
  let {
    req,
    productName
  } = _ref3;
  const DELIVERED_DRAW_FIELDS = ['drawNumber', 'productId', 'userProduct', 'regCloseTime', 'fund.extraMoney', 'fund.rolloverIn'];
  const _getContexts = productName => {
    const TOPPTIPSET_PRODUCT_CONTEXTS = 'draw_topptipset,draw_topptipsetextra,draw_topptipsetstryk';
    const OTHER_POOL_PRODUCT_CONTEXTS = 'draw_challenge,draw_bomben,draw_stryktipset,draw_europatipset,draw_powerplay,draw_maltipset,draw_matchen';
    if (productName === 'topptipset') {
      return TOPPTIPSET_PRODUCT_CONTEXTS;
    }
    return productName ? "draw_".concat(productName) : "".concat(OTHER_POOL_PRODUCT_CONTEXTS, ",").concat(TOPPTIPSET_PRODUCT_CONTEXTS);
  };
  const parameters = {
    ctx: _getContexts(productName),
    andfilter: 'payload.draw.drawState;Open',
    includes: DELIVERED_DRAW_FIELDS.map(key => "payload.draw.".concat(key)).join(','),
    count: NUMBER_OF_POOL_DRAWS_TO_CERTAINLY_QUERY_ANYTHING_FROM_TODAY_AND_TOMORROW,
    sort: 'payload.draw.regCloseTime;asc'
  };
  const queryString = Object.keys(parameters).map(key => "".concat(key, "=").concat(encodeURIComponent(parameters[key]))).join('&');
  return trinidad.jupiter.callAsync({
    method: 'GET',
    path: "/search/1/query?".concat(queryString),
    req
  });
}

async function _extractDrawsFromResponseAndIncludeJackpots(_ref4) {
  let {
    req,
    sortedDrawResponse,
    allPoolBrandingsByPriority
  } = _ref4;
  if (!sortedDrawResponse) {
    return [];
  }

  const allSortedDraws = sortedDrawResponse.result.map(responseItem => _extractDrawData(responseItem.payload.draw)).filter(item => item && allPoolBrandingsByPriority.includes(item.productClassName));
  if (allSortedDraws.length === 0) {
    return [];
  }
  const allJackpots = await mockableImports.getJackpots({
    req
  });
  const _extractJackpotData = _ref5 => {
    let {
      defaultJackpot,
      productId,
      drawNumber,
      jackpotAmount
    } = _ref5;
    if (defaultJackpot) {
      return null;
    }
    return {
      productId,
      drawNumber,
      jackpot: parseInt(jackpotAmount, 10)
    };
  };
  const allNonDefaultJackpots = Array.isArray(allJackpots) ? allJackpots.map(_extractJackpotData).filter(item => item && item.jackpot > 0) : [];
  allSortedDraws.forEach(drawItem => {
    const jackpotItem = allNonDefaultJackpots.find(item => (item === null || item === void 0 ? void 0 : item.productId) === drawItem.productId && (item === null || item === void 0 ? void 0 : item.drawNumber) === drawItem.drawNumber);
    if (jackpotItem) {
      drawItem.jackpot = jackpotItem.jackpot;
    }
  });
  return allSortedDraws;
}

function _extractDrawData(rawApiData) {
  if (!rawApiData) {
    return null;
  }
  const {
    drawNumber,
    productId,
    userProduct,
    regCloseTime: closeTime,
    fund
  } = rawApiData;
  const branding = getProductBrand(productId, null);
  const productClassName = getProductClassName(productId);
  const extraMoney = fund && fund.extraMoney ? parseInt(fund.extraMoney, 10) : 0;
  const rolloverIn = fund && fund.rolloverIn ? parseInt(fund.rolloverIn, 10) : 0;
  return {
    drawNumber,
    productId,
    userProduct,
    branding,
    closeTime,
    jackpot: null,
    extraMoney,
    rolloverIn,
    productClassName
  };
}

function _getBestMatchingDrawItem(_ref6) {
  let {
    sortedDrawsWithJackpot,
    preselection,
    allPoolBrandingsByPriority
  } = _ref6;
  if (sortedDrawsWithJackpot.length === 0) {
    return null;
  }
  const preselectedBranding = (preselection === null || preselection === void 0 ? void 0 : preselection.branding) || (preselection === null || preselection === void 0 ? void 0 : preselection.brandingIfDrawDataExists);
  if (preselectedBranding) {
    const draw = sortedDrawsWithJackpot.find(item => item.productClassName === preselectedBranding);
    if (draw) {
      return draw;
    }
    if (preselection.branding) {
      return null;
    }
  }
  const dateToday = new Date().toISOString().split('T')[0];
  const sortedDrawsFromTodayWithAnyExtraMoney = sortedDrawsWithJackpot.filter(item => {
    var _item$closeTime;
    return ((_item$closeTime = item.closeTime) === null || _item$closeTime === void 0 ? void 0 : _item$closeTime.split('T')[0]) === dateToday;
  })
  .filter(item => item.jackpot > 0 || item.extraMoney > 0 || item.rolloverIn > 0);
  const sortedDraws = sortedDrawsFromTodayWithAnyExtraMoney.length > 0 ? sortedDrawsFromTodayWithAnyExtraMoney : sortedDrawsWithJackpot;
  return _findDrawWithEarliestCloseTimeAndHighestPriority({
    sortedDraws,
    allPoolBrandingsByPriority
  });
}

function _findDrawWithEarliestCloseTimeAndHighestPriority(_ref7) {
  let {
    sortedDraws,
    allPoolBrandingsByPriority
  } = _ref7;
  const earliestCloseTime = sortedDraws[0].closeTime;
  const drawsWithEarliestCloseTime = sortedDraws.filter(item => item.closeTime === earliestCloseTime);
  const _getPriority = item => allPoolBrandingsByPriority.indexOf(item.productClassName);
  const bestProductPriority = Math.min(...drawsWithEarliestCloseTime.map(_getPriority));
  const draw = drawsWithEarliestCloseTime.find(item => _getPriority(item) === bestProductPriority);
  return draw || null;
}

if (svs.isServer) {
  module.exports = {
    queryDrawDataAsync
  };
} else {
  setGlobal('svs.sport.productsGridWidget.queryDrawDataAsync', queryDrawDataAsync);
  setGlobal('svs.sport.productsGridWidget._testInternals.queryDrawDataAsync.getMockableImports', () => mockableImports);
}

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/init.js');
"use strict";



function init(options) {
  const widget = options.$el[0];
  _prepareScrollButtonVisibility(widget);
  _prepareTracking(widget);
}

function _prepareScrollButtonVisibility(widget) {
  const buttonToProductsSelector = widget.getElementsByClassName('js-products-grid-scroll-button')[0];
  const productsSelector = document.getElementsByClassName('js-widget-products-selector')[0];
  if (buttonToProductsSelector && productsSelector) {
    buttonToProductsSelector.setAttribute('style', 'visibility: visible;');
    buttonToProductsSelector.onclick = _scrollPageToProductsSelector;
  }
}
function _scrollPageToProductsSelector() {
  const productsSelector = document.getElementsByClassName('js-widget-products-selector')[0];
  if (productsSelector && productsSelector.parentNode) {
    const sideHeader = document.getElementsByClassName('js-fixed-top-content')[0];
    const headerHeight = sideHeader ? sideHeader.getBoundingClientRect().height : 0;
    const productsSelectorY = productsSelector.parentNode.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
    window.scrollTo({
      left: 0,
      top: productsSelectorY - headerHeight,
      behavior: 'smooth'
    });
  }
}

function _prepareTracking(widget) {
  const productItemElements = widget.getElementsByClassName('js-products-grid-item');
  for (let index = 0; index < productItemElements.length; index++) {
    const element = productItemElements[index];
    const linkElement = element.getElementsByTagName('a')[0];
    const trackingName = element.dataset.trackingName;
    if (linkElement && trackingName) {
      linkElement.addEventListener('click', event => _handleClickProduct({
        event,
        trackingName
      }));
    }
  }
}

function _handleClickProduct(_ref) {
  let {
    event,
    trackingName
  } = _ref;
  const element = event.currentTarget;
  if (!element) {
    return;
  }
  const widget = _findParentWithGivenClassName({
    element,
    className: 'widget'
  });
  const position = _getElementIndexInsideParentNode({
    element: widget
  }) + 1;
  const trackingEvent = {
    name: trackingName,
    position
  };
  const hasSpecialLinkTarget = element.getAttribute('target') && element.getAttribute('target') !== '_self';
  if (hasSpecialLinkTarget) {
    svs.components.analytics.trackPromoClick(trackingEvent);
    return;
  }
  event.preventDefault();
  const url = element.getAttribute('href');
  svs.components.analytics.trackPromoClick(trackingEvent, () => {
    if (url) {
      document.location = url;
    }
  });
}

function _findParentWithGivenClassName(_ref2) {
  let {
    element,
    className
  } = _ref2;
  if (element && className) {
    let node = element;
    while (node) {
      node = node.parentNode;
      const currClassNames = node.getAttribute('class') || '';
      if (currClassNames.split(' ').includes(className)) {
        return node;
      }
    }
  }
  return null;
}

function _getElementIndexInsideParentNode(_ref3) {
  let {
    element
  } = _ref3;
  if (element !== null && element !== void 0 && element.parentNode) {
    const allNodesWithSameParent = element.parentNode.children || [];
    for (let index = 0; index < allNodesWithSameParent.length; index++) {
      if (allNodesWithSameParent[index] === element) {
        return index;
      }
    }
  }
  return -1;
}

setGlobal('svs.sport.productsGridWidget.init', init);

 })(window);
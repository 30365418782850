(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/prepare-item-models.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/prepare-item-models.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const products = svs.isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const {
  productIds: {
    FULLTRAFF
  }
} = products;
const brandMappingPath = '../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6';
const {
  getProductBrand
} = svs.isServer ? require(brandMappingPath) : svs.components.lbUtils.brandMapping.fn;

const {
  GridItemSizes
} = svs.isServer ? require('./constants.es6') : svs.sport.productsGridWidget.constants;

const productsGridWidgetMockable = svs.isServer ? _objectSpread(_objectSpread(_objectSpread({}, require('./prepare-tipsen-item.es6')), require('./prepare-open-fulltraff-item.es6')), require('./prepare-product-item.es6')) : svs.sport.productsGridWidget;
const FULLTRAFF_ITEM_SIZE = GridItemSizes.SMALL;
const getMapOfItemIndexToSize = _ref => {
  let {
    hasOpenFulltraff
  } = _ref;
  return [GridItemSizes.LARGE, GridItemSizes.MEDIUM, GridItemSizes.SMALL, GridItemSizes.SMALL, GridItemSizes.SMALL, hasOpenFulltraff ? GridItemSizes.SPECIAL_MEDIUM_OR_SMALL : GridItemSizes.SMALL];
};

async function prepareItemModels(_ref2) {
  let {
    config,
    isMobile,
    trackingPrefix,
    req
  } = _ref2;
  const {
    widgetId,
    tipsen,
    otherProducts
  } = config;
  const fulltraffConfigIndex = otherProducts.findIndex(item => item.branding === getProductBrand(FULLTRAFF));
  const hasFulltraffConfig = fulltraffConfigIndex >= 0;
  let fulltraffItem = null;
  if (hasFulltraffConfig) {
    fulltraffItem = await productsGridWidgetMockable.prepareOpenFulltraffItem({
      itemConfig: otherProducts[fulltraffConfigIndex],
      isMobile,
      size: FULLTRAFF_ITEM_SIZE,
      trackingPrefix: "".concat(trackingPrefix, "/").concat(fulltraffConfigIndex + 1),
      widgetId,
      req
    });
  }
  const hasOpenFulltraff = Boolean(fulltraffItem);
  const mapItemIndexToSize = getMapOfItemIndexToSize({
    hasOpenFulltraff
  });
  const productItems = await Promise.all([productsGridWidgetMockable.prepareTipsenItem({
    itemConfig: tipsen,
    isMobile,
    size: mapItemIndexToSize.shift(),
    trackingPrefix: "".concat(trackingPrefix, "/0"),
    widgetId,
    req
  }), ...otherProducts.map((itemConfig, index) => {
    if (index === fulltraffConfigIndex) {
      return fulltraffItem;
    }
    if (mapItemIndexToSize.length === 0) {
      return null;
    }
    return productsGridWidgetMockable.prepareProductItem({
      itemConfig,
      isMobile,
      size: mapItemIndexToSize.shift(),
      trackingPrefix: "".concat(trackingPrefix, "/").concat(index + 1),
      widgetId,
      req
    });
  })]);
  return {
    productItems: productItems.filter(Boolean),
    hasOpenFulltraff
  };
}

if (svs.isServer) {
  module.exports = {
    prepareItemModels
  };
} else {
  setGlobal('svs.sport.productsGridWidget.prepareItemModels', prepareItemModels);
}

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/prepare-tipsen-item.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/prepare-tipsen-item.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const FALLBACK_PRODUCT_BRANDING = 'topptipset';
const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const {
  formatDate
} = svs.isServer ? trinidad.components.require('lb-utils', 'helper-formatdate').api : svs.components.lbUtils.helpers.formatDate;
const {
  Currency: formatCurrency
} = svs.isServer ? trinidad.components.require('utils', 'format').api : svs.utils.format;
const {
  productIds
} = svs.isServer ? require('trinidad-core').core.components.require('utils', 'products') : svs.utils.products;
const {
  getProductBrand
} = svs.isServer ? require('../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6') : svs.components.lbUtils.brandMapping.fn;

const {
  GridItemSizes
} = svs.isServer ? require('./constants.es6') : svs.sport.productsGridWidget.constants;

const productsGridWidgetMockable = svs.isServer ? _objectSpread({}, require('./query-draw-data-async.es6')) : svs.sport.productsGridWidget;

async function prepareTipsenItem(_ref) {
  let {
    itemConfig,
    isMobile,
    size,
    trackingPrefix,
    widgetId,
    req
  } = _ref;
  const {
    productsByPriority: products
  } = itemConfig;
  if (size !== GridItemSizes.LARGE) {
    throw new Error("prepareTipsenItem() failed: Size \"".concat(size, "\" not supported, no image-data in CMS-config"));
  }

  const {
    userSession
  } = svs.isServer ? req : svs.core;
  const isLoggedInAsAdmin = userSession.hasRole(userSession.roles.INTERNAL);
  const drawData = await productsGridWidgetMockable.queryDrawDataAsync({
    itemConfig,
    cacheKey: "productsGridTipsen-".concat(widgetId),
    req
  });
  const {
    closeTime,
    jackpot,
    extraMoney,
    rolloverIn,
    userProduct,
    productId
  } = drawData || {};
  const branding = drawData.branding || FALLBACK_PRODUCT_BRANDING;
  const productClassName = drawData.productClassName;
  const product = products.find(item => item.branding === branding) || products.find(item => item.branding === productClassName);
  if (!product && branding === FALLBACK_PRODUCT_BRANDING) {
    throw new Error("prepareTipsenItem() failed - Can't use fallback product, invalid CMS config");
  }
  const title = _getJackpotText({
    jackpot,
    rolloverIn,
    isMobile
  }) || product.title;
  const subTitle = _getCloseTimeText({
    closeTime
  }) || product.subTitle;
  const logoText = _getLogoText({
    jackpot,
    extraMoney,
    rolloverIn
  });
  let typeOfSpeechBubble;
  let speechBubbleRotation;
  if (jackpot) {
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_1';
    speechBubbleRotation = 'positive';
  } else if (rolloverIn) {
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_2';
    speechBubbleRotation = 'negative';
  } else if (extraMoney) {
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_3';
    speechBubbleRotation = 'negative';
  }
  const {
    images,
    filterOpacity,
    gradientOpacity,
    urlMapping,
    bannerText
  } = product;
  const imageMobile = images.mobileLarge;
  const imageDesktop = images.desktopLarge;
  const displayBanner = typeof bannerText === 'string' && bannerText.trim() !== '' && !jackpot && !extraMoney && !rolloverIn;
  const _getWarningMessage = () => {
    if (!drawData.branding) {
      return 'API request failed - fallback-product active';
    }
    const targetsWithoutImageData = [imageMobile ? '' : 'mobile', imageDesktop ? '' : 'desktop'].filter(s => s);
    if (targetsWithoutImageData.length > 0) {
      return "Image missing (".concat(targetsWithoutImageData.join(', '), ")");
    }
    if (!urlMapping) {
      return 'URL-mapping missing';
    }
    return null;
  };
  const warning = isLoggedInAsAdmin ? _getWarningMessage() : null;
  const trackingString = "".concat(trackingPrefix, "/tipsen-product/").concat(branding);
  const updatedBranding = productId === productIds.EUROPATIPSET ? getProductBrand(productId, null, userProduct) : branding;
  return {
    branding: updatedBranding,
    imageMobile,
    imageDesktop,
    filterOpacity,
    gradientOpacity,
    title,
    subTitle,
    logoText,
    urlMapping,
    size,
    warning,
    trackingString,
    typeOfSpeechBubble,
    speechBubbleRotation,
    displayBanner,
    bannerText
  };
}

function _getCloseTimeText(_ref2) {
  let {
    closeTime
  } = _ref2;
  const now = new Date();
  const time = new Date(closeTime);
  if (!closeTime || time < now) {
    return null;
  }
  return "Spelstopp ".concat(formatDate(time, true, true));
}

function _getJackpotText(_ref3) {
  let {
    jackpot,
    rolloverIn,
    isMobile
  } = _ref3;
  if (jackpot) {
    return "".concat(formatCurrency(jackpot), " ").concat(isMobile ? 'kr' : 'kronor');
  }
  if (rolloverIn) {
    return "".concat(formatCurrency(rolloverIn), " ").concat(isMobile ? 'kr' : 'kronor');
  }
  return null;
}

function _getLogoText(_ref4) {
  let {
    jackpot,
    extraMoney,
    rolloverIn
  } = _ref4;
  if (jackpot) {
    return 'Jackpot!';
  }
  if (extraMoney) {
    return "".concat(formatCurrency(extraMoney), " kr extra!");
  }
  if (rolloverIn) {
    return 'Rullpott!';
  }
}

if (svs.isServer) {
  module.exports = {
    prepareTipsenItem
  };
} else {
  setGlobal('svs.sport.productsGridWidget.prepareTipsenItem', prepareTipsenItem);
}

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/constants.js');
"use strict";


const GridItemSizes = Object.freeze({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  SPECIAL_MEDIUM_OR_SMALL: 'mobile-medium-desktop-small'
});

if (svs.isServer) {
  module.exports = {
    GridItemSizes
  };
} else {
  setGlobal('svs.sport.productsGridWidget.constants', {
    GridItemSizes
  });
}

 })(window);
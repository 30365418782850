(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/views/grid.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/views/grid.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.sport=_svs.sport||{};
_svs.sport.products_grid_widget=_svs.sport.products_grid_widget||{};
_svs.sport.products_grid_widget.templates=_svs.sport.products_grid_widget.templates||{};
svs.sport.products_grid_widget.templates.grid = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p class=\"products-grid-subtitle f-summary f-center\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subTitle") || (depth0 != null ? lookupProperty(depth0,"subTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subTitle","hash":{},"data":data,"loc":{"start":{"line":4,"column":57},"end":{"line":4,"column":69}}}) : helper)))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " open-fulltraff";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"products-grid-cell products-grid-cell-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"size") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isSecondSelectedProduct") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":69},"end":{"line":8,"column":161}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"products-grid-aspect-ratio-box\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-sport-products_grid_widget-item"),depth0,{"name":"components-sport-products_grid_widget-item","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n      </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " products-grid-cell-is-second-selected-product";
},"10":function(container,depth0,helpers,partials,data) {
    return "Till alla spel";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"products-grid f-content\" data-widget-admin-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widgetId") || (depth0 != null ? lookupProperty(depth0,"widgetId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widgetId","hash":{},"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":71}}}) : helper)))
    + "\">\n  <h2 class=\"products-grid-title h1 f-section-title f-center\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":62},"end":{"line":2,"column":71}}}) : helper)))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"products-grid-cells margin-top-2"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOpenFulltraff") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":6,"column":46},"end":{"line":6,"column":100}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"productItems") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"products-grid-scroll-button js-products-grid-scroll-button\">\n    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"transparent":true,"block":true,"size":300},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":78}}})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-sport-products_grid_widget-grid'] = svs.sport.products_grid_widget.templates.grid;
})(svs, Handlebars);


 })(window);